<template>
	<div
		@click="handleClick"
		:class="computedClass"
		class="medal d-flex align-items-center"
	>
		<Emblem
			:src="picture"
			:size="size"
			:skeleton="skeleton"
		/>

		<div
			v-if="name"
			class="medal__name-container d-flex align-items-center justify-content-center"
		>
			<TextPlay
				size="xs"
				weight="semibold"
				color="var(--gamification-badge-color)"
				:text="name"
				:skeleton="skeleton"
			/>
		</div>
	</div>
</template>

<script>
import Emblem from "@/components/Emblem.vue";

export default {
	components: {
		Emblem,
	},
	props: {
		name: {
			type: String,
			default: "",
		},
		picture: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "base",
			validator: function (value) {
				return ["xs", "sm", "base", "lg", "xl"].indexOf(value) !== -1;
			},
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleClick() {
			if (this.clickable) {
				this.$emit("click");
			}
		},
	},
	computed: {
		computedClass() {
			return {
				"medal--horizontal": this.horizontal,
				"medal--vertical": !this.horizontal,
				"medal--clickable": this.clickable,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.medal {
	.medal__name-container {
		margin-top: 8px;
		padding: 8px;
	}

	&--clickable {
		cursor: pointer;
	}

	&--horizontal {
		flex-direction: row;

		.medal__name-container {
			text-align: start;
			background-color: var(--caption-bg-color);
			border-radius: 4px;
			margin-left: 8px;
			margin-top: 0;

			.text-play {
				text-align: start;
				max-width: 23ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	&--vertical {
		flex-direction: column;

		.medal__name-container {
			width: 100%;
			text-align: center;
			background-color: var(--caption-bg-color);
			border-radius: 4px;

			.text-play {
				text-align: center;
				max-width: 23ch;
				width: 23ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>
